<template>
  <div class="pt-2 m-2 mt-0">
    <!-- 商品內容 -->
    <div>
      <p class="tw-border-start fw-bolder mb-3">商品內容</p>
      <ul class="list ms-3">
        <li>
          <span class="fw-bolder">商品分類:</span>
          <template
            v-if="
              merchandiseInfo.merchClassifications &&
              merchandiseInfo.merchClassifications.length > 0
            "
          >
            <span
              v-for="data in merchandiseInfo.merchClassifications"
              :key="data.id"
            >
              {{ data.name }}&ensp;
            </span>
          </template>
          <span class="text-danger fw-bolder" v-else>目前無任何商品分類!</span>
        </li>
        <li>
          <span>
            <span class="fw-bolder">訂單總數:</span
            ><span>{{ merchandiseInfo.merchOrdersQuantity }}筆</span> </span
          >&ensp;
          <span
            ><span class="fw-bolder">未配數量:</span
            ><span>{{ merchandiseInfo.unAllocatedQuantity }}筆</span></span
          >&ensp;
          <span
            ><span class="fw-bolder">叫貨數量:</span
            ><span>{{ merchandiseInfo.purchaseQuantity }}筆</span></span
          >
        </li>
        <li>
          <span class="fw-bolder">建立時間:</span>
          <span>{{
            $methods
              .moment(merchandiseInfo.createTime)
              .format("YYYY-MM-DD HH:mm:ss")
          }}</span>
        </li>
        <li>
          <span class="fw-bolder">商品描述:</span>
          <pre v-if="merchandiseInfo.description">{{
            merchandiseInfo.description
          }}</pre>
          <span class="fw-bolder text-danger" v-else>目前尚無商品描述!</span>
        </li>
      </ul>
    </div>
    <hr />
    <!-- 款式細項 -->
    <div>
      <p class="tw-border-start fw-bolder mb-3">款式細項</p>
      <div class="row">
        <div class="col-md-6 col-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>主要款式</th>
                <th>成本</th>
                <th>單價</th>
                <th>優惠</th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="data in merchandiseInfo.merchandiseStyles"
                :key="data.id"
              >
                <tr v-if="data.hierarchy === 0">
                  <td>
                    <p v-if="!data.deleted">{{ data.name }}</p>
                    <del class="text-danger" v-else>{{ data.name }}</del>
                  </td>
                  <td>{{ data.cost }}</td>
                  <td>{{ data.price }}</td>
                  <td class="text-start">
                    <ul style="list-style-type: square">
                      <li
                        v-for="discount in data.merchandiseDiscounts"
                        :key="discount.id"
                      >
                        <span
                          v-if="discount.minQuantity && discount.maxQuantity"
                        >
                          {{ discount.minQuantity }}~{{
                            discount.maxQuantity
                          }}個
                        </span>
                        <span
                          v-else-if="
                            discount.minQuantity && !discount.maxQuantity
                          "
                        >
                          {{ discount.minQuantity }}個以上
                        </span>
                        <span>，優惠價:{{ discount.price }}</span>
                        <span v-if="discount.storeVipId"
                          >，VIP: {{ discount.storeVip.name }}</span
                        >
                      </li>
                    </ul>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="col-md-6 col-12 table-responsive">
          <table class="table table-hover">
            <thead>
              <tr>
                <th style="min-width: 100px">細項</th>
                <th class="text-start">名稱</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>細項一</td>
                <td class="text-start">
                  <template
                    v-for="data in merchandiseInfo.merchandiseStyles"
                    :key="data.id"
                  >
                    <span v-if="data.hierarchy === 1 && !data.deleted">
                      {{ data.name }}&ensp;
                    </span>
                    <del
                      class="text-danger"
                      v-else-if="data.hierarchy === 1 && data.deleted"
                    >
                      {{ data.name }}&ensp;
                    </del>
                  </template>
                </td>
              </tr>
              <tr>
                <td>細項二</td>
                <td class="text-start">
                  <template
                    v-for="data in merchandiseInfo.merchandiseStyles"
                    :key="data.id"
                  >
                    <span v-if="data.hierarchy === 2 && !data.deleted">
                      {{ data.name }}&ensp;
                    </span>
                    <del
                      class="text-danger"
                      v-else-if="data.hierarchy === 2 && data.deleted"
                    >
                      {{ data.name }}&ensp;
                    </del>
                  </template>
                </td>
              </tr>
              <tr>
                <td>細項三</td>
                <td class="text-start">
                  <template
                    v-for="data in merchandiseInfo.merchandiseStyles"
                    :key="data.id"
                  >
                    <span v-if="data.hierarchy === 3 && !data.deleted">
                      {{ data.name }}&ensp;
                    </span>
                    <del
                      class="text-danger"
                      v-else-if="data.hierarchy === 3 && data.deleted"
                    >
                      {{ data.name }}&ensp;
                    </del>
                  </template>
                </td>
              </tr>
              <tr>
                <td>細項四</td>
                <td class="text-start">
                  <template
                    v-for="data in merchandiseInfo.merchandiseStyles"
                    :key="data.id"
                  >
                    <span v-if="data.hierarchy === 4 && !data.deleted">
                      {{ data.name }}&ensp;
                    </span>
                    <del
                      class="text-danger"
                      v-else-if="data.hierarchy === 4 && data.deleted"
                    >
                      {{ data.name }}&ensp;
                    </del>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <hr />
    <!-- 供應商關聯 -->
    <div>
      <p class="tw-border-start fw-bolder mb-3">供應商關聯</p>
      <div class="table-responsive">
        <table
          class="table table-hover"
          v-if="
            merchandiseInfo.merchandiseSupplierLinks &&
            merchandiseInfo.merchandiseSupplierLinks.length > 0
          "
        >
          <thead>
            <tr>
              <th>名稱</th>
              <th>成本</th>
              <th>狀態</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="data in merchandiseInfo.merchandiseSupplierLinks"
              :key="data.id"
            >
              <td>{{ data.supplier.name }}</td>
              <td>{{ data.cost }}</td>
              <td>
                <span class="text-success" v-if="data.preset"
                  >為預設供應商</span
                >
                <span class="text-danger" v-else>關聯過供應商</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="fw-bolder text-danger" v-else>目前尚未關聯過任何供應商!</p>
      </div>
    </div>
    <hr />
    <!-- 庫存列表 -->
    <div>
      <p class="tw-border-start fw-bolder mb-3">庫存列表</p>
      <div class="table-responsive" v-if="!stocks.loading">
        <table class="table table-hover" v-if="stocks.data.length > 0">
          <thead>
            <tr>
              <th class="text-start" style="width: 400px">款式</th>
              <th>訂單總數</th>
              <th>未完成訂單數</th>
              <th>倉庫總數</th>
              <th>可配現貨</th>
              <th>已配貨數</th>
              <th>未配貨數</th>
              <th>已叫貨數</th>
              <th>待叫貨數</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="data in stocks.data" :key="data.id">
              <td class="text-start">
                <span v-if="!data.styleIsDeleted">{{ data.styleString }}</span>
                <del class="text-danger" v-else>{{ data.styleString }}</del>
              </td>
              <td>{{ data.merchOrderQuantity }}</td>
              <td>{{ data.unfinishMerchOrder }}</td>
              <td>{{ data.quantity }}</td>
              <td>{{ data.availableFromStockQuantity }}</td>
              <td>{{ data.allocatedQuantity }}</td>
              <td>{{ data.unAllocatedQuantity }}</td>
              <td>{{ data.purchasedQuantity }}</td>
              <td>{{ data.unPurchaseOrderQuantity }}</td>
            </tr>
          </tbody>
        </table>
        <p class="fw-bolder text-danger" v-else>目前尚未有任何庫存!</p>
      </div>
      <AreaLoading v-else></AreaLoading>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      serverToken: '',
      storeId: 0,
      merchandiseId: 0,
      merchandiseInfo: {},
      stocks: {
        loading: false,
        data: []
      },
    }
  },
  created() {
    this.initialization()
    this.getMerchandiseInfo()
    this.getStocks()
  },
  methods: {
    // 初始化
    initialization() {
      this.serverToken = this.$methods.getCookie('serverToken')
      this.storeId = this.$route.params.storeId
      this.merchandiseId = this.$route.query.merchandiseId
    },
    // 取得商品資訊
    getMerchandiseInfo() {
      this.$methods.switchLoading('show')
      const vm = this
      const getMerchandiseInfoApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getMerchClassifications,getMerchandiseStyles{getMerchandiseDiscounts{getStoreVip}},getMerchOrders,getStocks{getPurchaseOrderBodies},getMerchandiseSupplierLinks{getSupplier}}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getMerchandiseInfoApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            // 計算訂單數量，未配數量
            merchandiseInfo.merchOrdersQuantity = 0
            merchandiseInfo.unAllocatedQuantity = 0
            merchandiseInfo.merchOrders.forEach(item => {
              merchandiseInfo.merchOrdersQuantity += item.quantity
              merchandiseInfo.unAllocatedQuantity += item.quantity - item.allocatedQuantity
            });
            merchandiseInfo.purchaseQuantity = 0
            // 計算叫貨數量
            merchandiseInfo.stocks.forEach(item => {
              item.purchaseOrderBodies.forEach(body => {
                merchandiseInfo.purchaseQuantity += body.quantity
              })
            })
            vm.merchandiseInfo = merchandiseInfo
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
    // 取得庫存列表
    getStocks() {
      const vm = this
      const getStocksApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.serverToken
      }
      const data = [
        {
          type: 16,
          ids: [this.merchandiseId],
          methods: '{getStocks{getMerchandiseStyles,getMerchOrders,getAllocatedQuantity,getPurchasedQuantity,getAvailableFromStockQuantity,getTotalShortageQuantity,getOutOfStockQuantity,getShippedQuantity}}'
        }
      ]
      this.stocks = {
        loading: false,
        data: []
      }
      $.ajax({
        type: 'POST',
        async: true,
        url: getStocksApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const merchandiseInfo = res.data[0].objects[0]
            merchandiseInfo.stocks.forEach(item => {
              // 紀錄款式是否有被刪除
              item.styleIsDeleted = false
              let styleString = ''
              item.merchandiseStyles.forEach(style => {
                if (style.deleted) item.styleIsDeleted = true
                styleString += `${style.name}，`
              })
              item.styleString = styleString.substring(0, styleString.length - 1)
              // 計算訂單總數量
              item.merchOrderQuantity = 0;
              item.merchOrders.forEach((merchOrder) => {
                item.merchOrderQuantity += merchOrder.quantity;
              });
              // 計算未完成訂單總數
              item.unfinishMerchOrder = item.merchOrderQuantity - item.shippedQuantity;
              // 計算未配貨數
              item.unAllocatedQuantity = Math.max(item.unfinishMerchOrder - item.allocatedQuantity, 0);
              // 計算待叫貨數 (待叫貨數 === 還缺全)
              item.unPurchaseOrderQuantity = item.totalShortageQuantity;
            })
            vm.stocks = {
              loading: false,
              data: merchandiseInfo.stocks
            }
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
          this.dataTabelLoading = false
        },
      })
    },
  },
}
</script>